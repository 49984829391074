// TEST
console.log("main script working");
// MAIN JAVASCRIPT

// Firebase Services
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, push, set } from "firebase/database";

// Firebase Configuration
const firebaseConfig = {
  apiKey: "AIzaSyBDEqd1--7URDcQx0cRk0vSaEG_52SJfhw",
  authDomain: "capetonianwriter.firebaseapp.com",
  databaseURL: "https://capetonianwriter-default-rtdb.firebaseio.com",
  projectId: "capetonianwriter",
  storageBucket: "capetonianwriter.appspot.com",
  messagingSenderId: "667022088159",
  appId: "1:667022088159:web:1c12b42712cc083532166a",
  measurementId: "G-W88N3YVHVZ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const database = getDatabase(app);

// SUBSCRIBE FORM
document.getElementById("form-data").addEventListener("submit", submitForm);

// Submit form
function submitForm(e) {
  e.preventDefault();

  // Get Values
  const name = getInputVal("name");
  const email = getInputVal("email");

  // console.log(123);

  // Save Message
  saveMessage(name, email);

  // Show Alert
  document.querySelector(".alert").style.display = "block";

  // Hide Alert after 3 seconds
  setTimeout(function () {
    document.querySelector(".alert").style.display = "none";
  }, 10000);

  // Clear form
  document.getElementById("form-data").reset();
}

// Function to get form values
function getInputVal(id) {
  return document.getElementById(id).value;
}

//Save message to firebabse
function saveMessage(name, email) {
  const db = getDatabase();
  const messagesRef = ref(db, "subscribers");
  const newMessageRef = push(messagesRef);
  set(newMessageRef, {
    username: name,
    email: email,
  });
}
